<template>
  <div class="main1">
    <ul class="flex">
      <li :class="navIndex === idx ? 'active':''" v-for="(item,idx) in colum" :key="idx" @click="changeNav(idx, item)">
        <span class="a_item">{{item.columnName}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { getColumnID, getColumnList } from '@/api/raisingpigs'
export default {
  name: 'secondNav',
  data () {
    return {
      index: 0,
      colum: '',
      navIndex: 0,
      isHome: true
    }
  },
  methods: {
    getcolum () {
      getColumnID({
        parentId: 0
      }).then((res) => {
        const pid = res.data.records[0].id
        getColumnList({
          parentId: pid
        }).then((res) => {
          const id=res.data[1].id
          getColumnList({
            parentId: id,
          }).then((res) => {
            this.colum=res.data
          })
        })
      })
    },
    changeNav (index, item) {
      this.navIndex = index // 当前选中的导航
      this.$router.push(item.accessPath)
    }
  },
  mounted () {
    this.getcolum()
  }
}
</script>

<style scoped>
.main1{
    border-bottom: 1px solid #e5e5e5;
    margin: 20px 0;
}
.main1 li{
    padding:8px 18px;
    text-align: center;
    background-color: #F0F9EB;
    margin-right: 20px;
    cursor: pointer;
    border-radius: 4px;
    display: inline-block;
}
.main1 .active a {
     color: #fff;
}
.main1 a{color:#666;}
.main1 .active{
     background-color: #93b067;
     color: #fff;
}
</style>
