import { getRequest } from '@/api/axios'

// 获取栏目id
export const getColumnID = (params) => {
  return getRequest('/blade-integrate/openapi/integratecolumninfo/list', params)
}
// 获取栏目信息
export const getColumnList = (params) => {
  return getRequest('/blade-integrate/openapi/integratecolumninfo/column-list', params)
}
