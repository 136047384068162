<template>
  <div class="container">
      <SecondNav></SecondNav>
      <RaisingNav></RaisingNav>
      <div class="flex raispig">
        <div class="raispigLeft">
          <div class="raispigLeftTop"><h1>新兴县生猪价格指数</h1></div>
          <div class="raispigLeftBottom">
            <div>
              <div class="raispigLeftBottomTop flex">
                <div class="raispigLeftBottomTopleft">
                    <h4>2020年12月29日猪价指数</h4>
                    <div class="flex">
                      <div class="flex">
                        <h1 class="">206.19</h1>
                        <img class="" src="../../assets/img/arr-up.png" alt="">
                      </div>
                      <div class="">
                        <p>3.68</p>
                        <p>↑<span>1.92</span>%</p>
                      </div>
                    </div>
                </div>
                <div class="raispigLeftBottomTopcenter flex">
                    <div>品种</div>
                    <div>
                      <ul>
                        <li class="raispigLeftBottomTopcenterAvtive">猪</li>
                      </ul>
                    </div>
                </div>
                <div class="raispigLeftBottomTopright"><button>刷新报价</button></div>
              </div>
              <div class="raispigLeftBottomBottom flex">
                <ul>
                  <li :class="index==idx?'raispigLeftBottomBottomActive':''" v-for="(item,idx) in time" :key="idx" @click="son(item,idx)">{{item.time}}</li>
                </ul>
                <div class="flex">
                  <input type="text" id="start-time" placeholder="2020-11-27"><span>到</span><input type="text" id="end-time" placeholder="2020-11-27">
                </div>
              </div>
               <div id="myChart" :style="{width:'100%', height: '300px'}"></div>
            </div>
          </div>
        </div>
        <div class="raispigCenter">
          <div>
            <img src="../../assets/img/pig.jpg" alt="">
            <div>技术资料</div>
          </div>
          <div>
            <h1>为什么春季猪容易呕吐？应该怎样预防和解决?</h1>
              <p>
                近日，在啊啊大会打洞把红色的结案时间北大教授吧机会大把说不打就不打就不会撒比大家不是都节哀顺便的骄傲不对劲案板上的静安别墅大家把接到报警后撒娇不对劲啊说不定节哀顺便的机会的酒吧时段就会把就不打搅就哈不多节哀顺便的骄傲比较多到静安寺不达标世界杯撒娇不是大家都把就不对劲啊就是把的骄傲白色捷达比较多吧骄傲的是比较哈不多静安别墅酒店吉萨不对劲啊说不定节哀顺变的骄傲说不定就阿巴斯大家就傻逼的哈不多哈比的机会啊啊很简单吧就不打就不打大巴金山毒霸就不对劲啊的骄傲不对劲啊不对劲啊白色的的骄傲博士答辩的就按部就班的啊就不对劲啊不对就不急哈说不定就
              </p>
            <span><a href="#">[详细]</a></span>
          </div>
        </div>
        <div class="raispigRight">
          <div>
            <img src="../../assets/img/pig2.jpeg" alt="">
            <div>技术资料</div>
          </div>
          <div>
            <h1>2020年12月29日全国猪价行情评述</h1>
            <p>
              近日，在啊啊大会打洞把红色的结案时间北大教授吧机会大把说不打就不打就不会撒比大家不是都节哀顺便的骄傲不对劲案板上的静安别墅大家把接到报警后撒娇不对劲啊说不定节哀顺便的机会的酒吧时段就会把就不打搅就哈不多节哀顺便的骄傲比较多到静安寺不达标世界杯撒娇不是大家都把就不对劲啊就是把的骄傲白色捷达比较多吧骄傲的是比较哈不多静安别墅酒店吉萨不对劲啊说不定节哀顺变的骄傲说不定就阿巴斯大家就傻逼的哈不多哈比的机会啊啊很简单吧就不打就不打大巴金山毒霸就不对劲啊的骄傲不对劲啊不对劲啊白色的的骄傲博士答辩的就按部就班的啊就不对劲啊不对就不急哈说不定就
              </p>
              <span><a href="#">[详细]</a></span>
          </div>
        </div>
      </div>
      <div class="list">
        <div class="listTop">
          <div class="listTopHeader"></div>
          <div class="lisTopFooter flex">
            <div class="lisTopFooterLeft">
              <div>
                <h1 class="title">猪价行情</h1>
              </div>
              <div>
                <ul>
                  <li v-for="(item,idx) in Prico" :key="idx">{{item.title}}</li>
                </ul>
              </div>
            </div>
            <div class="lisTopFooterRight">
              <div>
                <h1 class="title">猪业资讯</h1>
              </div>
              <div>
                <div>
                  <ul>
                     <li v-for="(item,idx) in pignews" :key="idx">{{item.title}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="listTop">
          <div class="listTopHeader"></div>
          <div class="lisTopFooter flex">
            <div class="lisTopFooterLeft">
              <div>
                <h1 class="title">猪病防治</h1>
              </div>
              <div>
                <ul v-for="(item,idx) in discontrol.slice(0,10)" :key="idx">
                  <li v-for="(items,index) in item.diseaseList" :key="index">
                     <p>{{items.diseaseName}}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="lisTopFooterRight">
              <div>
                <h1 class="title">猪病大全</h1>
              </div>
              <div>
                <ul v-for="(item,idx) in pigdisea.slice(0,4)" :key="idx">
                  <li v-for="(items,index) in item.diseaseList" :key="index">
                     {{items.diseaseName}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import echarts from 'echarts'
import { getColumnID, getcolList } from '@/api/pigprico'
import { getdiseaseHot, getdisease } from '@/api/swinedisease'
import SecondNav from '../about/components/secondNav'
import RaisingNav from './components/raisingNav.vue'
export default {
  components: {
    SecondNav,
    RaisingNav
  },
  data () {
    return {
      time: [
        {
          time: '一周'
        },
        {
          time: '一月'
        },
        {
          time: '季度'
        },
        {
          time: '一年'
        }
      ],
      index: 0,
      Prico: '',
      pignews: '',
      discontrol: '',
      pigdisea: ''
    }
  },
  methods: {
    son (item, idx) {
      this.index = idx
    },
    drawLine () {
    // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('myChart'))
      myChart.setOption({
        xAxis: {
          type: 'category',
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: '#ddd'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#888'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#dedede'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#ddd'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#888'
            }
          },
          axisTick: {
            show: false
          }
        },
        series: [{
          data: [86, 107, 189.5, 195, 200, 287, 127],
          symbolSize: 6,
          symbol: 'circle',
          type: 'line',
          smooth: true,
          lineStyle: {
            normal: {
              color: '#4ddeb0'
            }
          },
          itemStyle: {
            normal: {
              color: '#4ddeb0',
              borderColor: '#4ddeb0'
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(54,206,158,0.3)'
                },
                {
                  offset: 1,
                  color: '#fff'
                }
              ])
            }
          }
        }]
      })
    },
    prico () {
      getColumnID({
        level: 3
      }).then((res) => {
        const cid = res.data.records[2].id
        getcolList({
          columnId: cid
        }).then((res) => {
          this.Prico = res.data.records
        })
      })
    },
    pigNews () {
      getColumnID({
        level: 3
      }).then((res) => {
        const cid = res.data.records[1].id
        getcolList({
          columnId: cid
        }).then((res) => {
          this.pignews = res.data.records
        })
      })
    },
    getdisea () {
      getdisease({
        sicked: 1
      }).then((res) => {
        this.discontrol = res.data
        console.log(res)
      })
    },
    getpigdisea () {
      getdisease({
        sicked: 0
      }).then((res) => {
        this.pigdisea = res.data
      })
    }
  },
  mounted () {
    this.drawLine()
    this.prico()
    this.pigNews()
    this.getdisea()
    this.getpigdisea()
  }
}
</script>

<style>
.rasipigTitle li{
    padding:8px 18px;
    text-align: center;
    background-color: #F0F9EB;
    margin-right: 20px;
    cursor: pointer;
    border-radius: 4px;
}
.rasipigTitle .active{
    background-color: #93b067;
    color: #fff;
}
.rasipigTitle .active a {
    color: #fff;
}
.rasipigTitle a{color:#666;}
.raispig{
    justify-content: space-between;
    font-size: 12px;
    padding: 0 10px;
}
.raispig>div{
    width: 32%;
}
.raispigLeftTop h1{
    font-size: 18px;
    text-align: left;
    margin: 25px 0;
    color: #666;

}
.raispigLeftTop h1:before {
    width: 5px;
    height: 18px;
    background:#93b067;
    vertical-align: middle;
    content: '';
    display: inline-block;
    margin-right: 15px;
}
.raispigLeftBottomTop>div{
  border: 1px solid #e5e5e5;
}
.raispigLeftBottomTopleft,.raispigLeftBottomTopcenter{
  width: 40%;
}
.raispigLeftBottomTopright{
    width: 20%;
}
.raispigLeftBottomTopleft h4{
    font-size: 12px;
}
.raispigLeftBottomTopleft h1{
    margin-top: 5px;
}
.raispigLeftBottomTopleft img{
    margin: 0 10px 0;
    width: 34px;
    height: 28px;
}
.raispigLeftBottomTopcenter{
    justify-content:flex-start;
    padding-top: 5px;
}
.raispigLeftBottomTopcenter>div:nth-child(1){
    position: relative;
    margin-left: 12px;
    margin-right: 2px;
    width: 30px;
}
.raispigLeftBottomTopcenter>div:nth-child(1)::before{
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-left:5px solid #799d42 ;
    position: absolute;
    left: -10px;
    top: 3px;
}
.raispigLeftBottomTopcenter>div:nth-child(2) ul{
    width: 100%;
}
.raispigLeftBottomTopcenter>div:nth-child(2) li{
    width:40px;
    text-align: center;
    border: 1px solid #d5d5d5;
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}
.raispigLeftBottomTopcenterAvtive{
    border: 2px solid #839855 !important;
    background-color: #eaf9e2;
    width: 38px !important;
    margin-bottom: 3px !important;
    position: relative;
    transition: all 0.5s ease;
}
.raispigLeftBottomTopcenterAvtive::before{
    content: '✔';
    display: block;
    height: 0px;
    width: 0px;
    position: absolute;
    bottom: 0;
    right: 0;
    color:#fff;
    /**对号大小*/
    font-size: 8px;
    line-height: 0px;
    border: 5px solid;
    border-color: transparent #9cb07b #9cb07b transparent;
}
.raispigLeftBottomTopright button{
    width: 90%;
    position: relative;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
    background-color: #789e4a;
    color: #fff;
    border: none;
    padding: 5px;
    cursor: pointer;
}
.raispigLeftBottomBottom{
    background-color: #e4ebd9;
    width: 100%;
    color: #757b7b;
    position: relative;
}
.raispigLeftBottomBottom ul li{
    float: left;
    width: 40px;
    padding: 8px 0;
    text-align: center;
    cursor: pointer;
}
.raispigLeftBottomBottomActive{
    background-color: #fff;
    border-top: 2px solid #8d9b6b;
}
.raispigLeftBottomBottom img{
    width: 16px;
    height: 16px;
}
.raispigLeftBottomBottom input{
    width: 80px;
    height: 20px;
    position: relative;
    padding: 5px;
}
.raispigLeftBottomBottom>div{
    padding-top: 7px;
    position: absolute;
    right: 3px;
}
.raispigLeftBottomBottom span{
    margin: 0 5px;
}
.raispigLeftBottomBottom input::after{
    content: "";
    width: 16px;
    height: 16px;
    background: url(../../assets/img/time.png);
    position: absolute;
    top: 0;
    left: 0;
    left: -30px;
    z-index: 2;
    background-size: 20px;
}
.listTop{
    margin-top: 30px;
    justify-content: left;
    text-align: left;
}
.listTopHeader,.new2 .new2_header{
    border-bottom: 1px solid #dcdcdc;
}
.lisTopFooter{
    font-size: 22px;
    justify-content: space-between;
}
.lisTopFooter .title{
    font-size: 20px;
    color:#93b067;
}
.lisTopFooter>div{
    width: 49%;
    padding: 10px;
    color: #666;
}
.lisTopFooterLeft .title,.lisTopFooterRight .title{
    border-bottom: 2px solid #93b067;

}
.new2 .lisTopFooterLeft>div:nth-child(1),.lisTopFooterRight>div:nth-child(1){
    border-bottom: 1px solid #cbccca;

}
.lisTopFooterLeft .title, .lisTopFooterRight .title{
    font-weight: bold;
    padding-bottom: 5px;
    margin-top: 10px;
}

.lisTopFooterLeft>div:nth-child(2) h1,.lisTopFooterRight>div:nth-child(2) h1{
    font-weight: bold;
    margin: 20px 0;
}
.lisTopFooterLeft>div:nth-child(2) li,.lisTopFooterRight>div:nth-child(2) li{
    margin: 10px 0;
    position: relative;
    margin-left: 20px;
    font-size: 14px;
    cursor: pointer;
}
.lisTopFooterLeft>div:nth-child(2) li:hover,.lisTopFooterRight>div:nth-child(2) li:hover {
    color: #93b067;
}
.lisTopFooterLeft>div:nth-child(2) li::before,.lisTopFooterRight>div:nth-child(2) li::before{
    content: "▪";
    position: absolute;
    left: -20px;
}
.lisTopFooterRight>div:nth-child(2)>div{
    width: 100%;
    height: 50%;
}
.lisTopFooterRight>div:nth-child(2) p{
    font-size: 18px;
    color: #767c76;
}
.lisTopFooterRight>div:nth-child(2)>div:nth-child(2){
position: relative;
bottom: -30px;
}
.raispigCenter,.raispigRight{
    width: 100%;
    font-size: 14px;
    border: 1px solid #f3f3f3;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);

}
.raispigCenter div:nth-child(1) img,.raispigRight div:nth-child(1) img{
    width: 100%;
    height: 255px;
}
.raispigCenter div:nth-child(1),.raispigRight div:nth-child(1){
    position: relative;
}
.raispigCenter div:nth-child(1) div,.raispigRight div:nth-child(1) div{
    width: 100px;
    padding: 10px;
    text-align: center;
    background-color: #3cb38d;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
}
.raispigRight div:nth-child(1) div{
    background-color: #3579a6;
}
.raispigCenter div:nth-child(2),.raispigRight div:nth-child(2){
    padding: 15px;
    color: #a2a6a8;
    text-align: left;
}
.raispigCenter div:nth-child(2) h1,.raispigRight div:nth-child(2) h1{
    font-weight: bold;
    font-size: 18px;

}
.raispigCenter div:nth-child(2) p,.raispigRight div:nth-child(2) p{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    font-size: 14px;
}
.raispigCenter div:nth-child(2) a,.raispigRight div:nth-child(2) a{
    color: #f2cb5f;
}
</style>
