<template>
  <div class="second_nav">
    <ul>
      <li :class="navIndex === 0 ? 'nav_active' : ''" @click="changeNav(0)">
        <span class="a_item">资讯首页</span>
      </li>
      <li
        :class="navIndex === idx + 1 ? 'nav_active' : ''"
        v-for="(item, idx) in colum"
        :key="idx"
        @click="changeNav(idx + 1, item)"
      >
        <span class="a_item">{{ item.columnName }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { getColumnID, getColumnList } from "@/api/raisingpigs";
export default {
  name: "secondNav",
  data() {
    return {
      index: 0,
      colum: "",
      navIndex: 0,
      isHome: true
    };
  },
  methods: {
    son(idx) {
      this.index = idx;
    },
    getcolum() {
      getColumnID({
        parentId: 0
      }).then(res => {
        const pid = res.data.records[0].id;
        getColumnList({
          parentId: pid
        }).then(res => {
          this.colum = res.data;
        });
      });
    },
    changeNav(index, item) {
      this.navIndex = index; // 当前选中的导航
      if (index === 0) {
        // 首页
        this.$router.push("/about");
      } else {
        this.$router.push(item.accessPath);
      }
    }
  },
  mounted() {
    this.getcolum();
  }
};
</script>

<style scoped>
.second_nav {
  padding-top: 12px;
  border-bottom: 1px solid #ccc;
}

.second_nav ul {
  font-size: 14px;
  text-align: left;
}

.second_nav ul li {
  padding: 8px 0px;
  text-align: center;
  margin-right: 25px;
  border-bottom: 0;
  display: inline-block;
  margin-left: 25px;
}

.second_nav ul li a {
  color: #666 !important;
}

.second_nav ul li.active {
  border-bottom: 3px solid #93b067;
}

.second_nav ul li.active a {
  color: #93b067 !important;
}
</style>
